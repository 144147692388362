<!--
Staff
-->
<template>
  <div v-if="this.data.length > 0" class="container px-0 mb-5 text-center">
    <ul id="staff" class="m-0 p-0">
      <li v-for="(item, index) in (this.data)" :key="index" class="d-inline-block m-2">
        <div class="text-center m-3">
          <img v-if="item.image !== null" class="img-circ"
               v-bind:src="item.image"
               v-bind:alt="item.title"/>
        </div>
        <div class="text-blue text-center m-3">
          <strong>{{ item.title }}</strong><br/>
          <div v-html="item.text"/>
        </div>
      </li>
    </ul>
  </div>

</template>

<script>

import _ from 'lodash';

export default {
  name: "module9",
  props: {
    data: {data: [Array]},
  },
  computed: {
    chunkedItems() {
      return _.chunk(this.data, 4)
    }
  }
}
</script>

<style scoped>

</style>